<template>
    <Popover
        v-slot="slotProps"
        class="relative"
        as="span"
        :class="popoverWrapperClasses">
        <div
            class="leading-3 inline"
            @mouseenter="handlePopoverMouseEnter(slotProps, $event)"
            @mouseleave="handlePopoverMouseLeave(slotProps, $event)">
            <PopoverButton
                ref="popoverTrigger"
                as="span"
                class="text-sm cursor-pointer">
                <slot name="trigger" />
            </PopoverButton>

            <Transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel
                    class="absolute z-10 flex w-screen max-w-max transition-opacity"
                    :class="[position === 'center' ? 'left-1/2' : position === 'left' ? 'left-0' : 'right-0', yPosition === 'top' ? 'bottom-[20px]' : 'top-[20px]']"
                    :static="showAlways"
                    :style="offset">
                    <div
                        ref="panel"
                        class="flex-auto rounded-xl px-6 py-4 overflow-hidden text-sm leading-6 shadow-lg ring-1 dark:ring-gray-300 ring-gray-900/5"
                        :class="popoverStyles">
                        <slot />
                    </div>
                </PopoverPanel>
            </Transition>
        </div>
    </Popover>
</template>

<script setup>
const popoverTrigger = ref(null);

const props = defineProps({
    size: {
        type: String,
        default: 'md',
        description: 'large: lg || medium:md || small: sm'
    },
    position: {
        type: String,
        default: 'center',
        description: 'center || left || right'
    },
    yPosition: {
        type: String,
        default: 'bottom',
        description: 'top || bottom'
    },
    trigger: {
        type: String,
        default: 'hover',
        description: 'hover || click'
    },
    showAlways: {
        type: Boolean,
        default: false,
        description: 'show popover always'
    },
    popoverColor: {
        type: String,
        default: 'default',
        description: 'color for popover (default || primary)'
    },
    popoverWrapperClasses: {
        type: String,
        default: '',
        description: 'additional classes for popover wrapper'
    }
})

const panel = ref(null)

const panelBoundingRect = useElementBounding(panel)

const {width: windowWidth} = useWindowSize()

const offset = computed(() => {
    if (!panelBoundingRect) return ''

    const PADDING = 15
    // popover default positions
    const posX = panelBoundingRect.x.value
    const rightX = posX + panelBoundingRect.width.value

    const leftOffset = (posX < 0) ? Math.floor(posX) - PADDING : 0
    const rightOffset = (rightX > windowWidth.value) ? Math.floor(rightX - windowWidth.value) + PADDING : 0

    //position by props, tailwindcss cannot handle dynamic class bindings at runtime, so this has to be done manually
    if (props.position === 'center') return `transform: translateX(calc(-50% - ${leftOffset}px - ${rightOffset}px));`
    if (props.position === 'left') return `transform: translateX(calc(-100% - ${leftOffset}px - ${rightOffset}px));`
    if (props.position === 'right') return `transform: translateX(calc(${leftOffset}px - ${rightOffset}px));`
})

function handlePopoverMouseEnter({open}, event) {
    if (open || props.trigger === 'click' || event.sourceCapabilities?.firesTouchEvents) return
    if (popoverTrigger.value) {
        popoverTrigger.value.$el.click()
    }
}

function handlePopoverMouseLeave({open, close}, event) {
    if (!open || props.trigger === 'click' || event.sourceCapabilities?.firesTouchEvents) return
    close()
}

const popoverStyles = computed(() => {
    let styles = ''

    if (props.size === 'sm') {
        styles = 'max-w-sm'
    } else if (props.size === 'md') {
        styles = 'max-w-md'
    } else {
        styles = 'max-w-lg'
    }

    styles += ' '

    if (props.popoverColor === 'primary') {
        styles += 'bg-primary-100 dark:bg-primary-800 text-primary-500 dark:text-primary-200' // todo!!!!!!!
    } else {
        styles += 'bg-white dark:bg-gray-800'
    }

    return styles
})
</script>

<style scoped>

</style>